<template>
  <div class="betslip-area">
    <component :is="betslipComponent" :bgImage="getBetslipBgImage"></component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GamesBetslipA, GamesBetslipB } from '@nsftx/games-sdk-js';
import isMobile from 'ismobilejs';

export default {
  name: 'BetslipArea',
  components: {
    GamesBetslipA,
    GamesBetslipB,
  },
  data() {
    return {
      loggedIn: false,
      showSingleBetSelected: true,
      mobile: window.innerWidth <= 660,
      removeBetsFromBetslip: true,
      modules: [
        {
          id: 1,
          name: 'future',
          checked: true,
        },
        {
          id: 2,
          name: 'possibleWin',
          checked: true,
        },
        {
          id: 3,
          name: 'numberOfBets',
          checked: true,
        },
        {
          id: 4,
          name: 'ticketHistory',
          checked: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'config',
      'user/user',
      'translations',
    ]),
    betslipComponent() {
      return this.isMob() ? 'GamesBetslipB' : 'GamesBetslipA';
    },
    isMobile() {
      return window.innerWidth < 660;
    },
    userProfile() {
      return !this['user/user'].profile.logged;
    },
    betslipTypes() {
      return [
        {
          id: 0,
          key: 0,
          type: 3,
          value: 'single',
          label: this.translations.general_ticket_single,
          active: true,
        },
      ];
    },
    racerLabel() {
      return this.translations.general_racer;
    },
    getBetslipBgImage() {
      if (this.config.ui.config.theme === 'light') {
        return '/assets/empty-betslip-light.png';
      }
      return '/assets/empty-betslip.png';
    },
  },
  methods: {
    isMob() {
      return isMobile().any || window.innerWidth <= 1099;
    },
  },
};
</script>

<style lang="scss" scoped>
.betslip-area {
  ::v-deep .betslip {
    div {
      div {
        .betslipFooter {
          .betslipFooterLower {
            .possibleWinWrapper {
              div {
                div {
                  .payOut {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.betslip-area {
  ::v-deep .betslipB {
    .betslipA {
      div {
        .betslip {
          div {
            div {
              .betslipFooter {
                .betslipFooterUpper {
                  .betslipUpperWrapperB {
                    .possibleWinWrapper {
                      div {
                        .payOut {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
::v-deep {
  .last-tickets-item .body .bet-item .bet-value .bet-status {
    width: 100%;
  }
}
::v-deep .overlay {
  left: 0px;
}
::v-deep .tab-button {
  max-width: unset;
}
@media (max-width: 420px) {
  ::v-deep .modal {
    .modal-header {
      padding-left: 16px !important;
    }
  }
}
@media (min-width: 660px) and (max-width: 1099px) {
  ::v-deep .betslipB {
    position: fixed;
    min-width: 360px;
    max-width: 800px;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
</style>
